import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://www.npmjs.com/package/hyper-matcha-controls"
      }}>{`hyper-matcha-controls`}</a></h1>
    <p>{`I'm a Linux lover and when the COVID pandemic started I had more time to create the ultimate Linux theme, so this was the reason to build this lib,
I wanted hyper to have the matcha gnome theme controls because it was looking odd with the rest of the OS.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/hyper-matcha.png",
        "alt": "screen-shot"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      